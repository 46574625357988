
.BackButton {
  font-size: 18px;
  color: rgba(9, 2, 36, 0.5);
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: initial;
  text-align: center;

  &.BackButtonFixed {
    position: fixed;
    bottom: 20px;
    left: 20px;  
  }
  
  svg {
    margin-top: 2px;
    width: 30px;
    height: 30px;
    color: rgba(9, 2, 36, 0.5);
  }

  &:hover {
    color: #2800f0;
    svg {
      color: #2800f0;
    }
  }
}